import React from 'react';
import primaryValues from '../constants/Values.js';
import '../App.css';
import '../style/Center.css';
import '../../node_modules/bootstrap-css-only/css/bootstrap.css';
import ReactTooltip from 'react-tooltip';
import * as Icons from "react-icons/md";
import * as IconsFa from "react-icons/fa";
import * as IconsMi from "react-icons/go";
import * as IconsIo from "react-icons/io";
import {IoIosClose, IoIosCopy, IoIosSave, IoIosSend, IoIosTrash, IoLogoWhatsapp} from "react-icons/io";
import AutocompleteApp from './AutocompleteApp';
import Input from '../components/InputApp';
import Button from '../components/ButtonApp';
import DatePick from '../components/DatePick';
import {connect} from "react-redux";
import {copyStringToClipboard} from '../sagas/sagas';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import TimePick from "./TimePick";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {camelCase, startCase} from "lodash";

const API_URL = `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_ENDPOINT}`;

class TableInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            clubs_list: this.props.initialState.clubs,
            users: this.props.initialState.user_permission === '1' ? this.props.initialState.users : [{name: this.props.initialState.user_email}],
            whatsapp: true,
            viber: true,
            gmail: true,
            sms: true,
            msg: '',
            error_msg: 0,
            inputMobileOpacity: 0.3,
            inputClubSmsPhone: '',
            whatsappChecked: false,
            viberChecked: false,
            gmailChecked: false,
            smsChecked: false,
            whatsappUrl: null,
            viberUrl: null,
            gmailUrl: null,
            sendBtn: null,
            address: '',
            club_selected: '',
            data: {
                id: this.props.row.id,
                date: this.props.row.date,
                order_time: this.props.row.order_time,
                club: this.props.row.club,
                notes: this.props.row.notes === `&nbsp;` ? `` : this.props.row.notes,
                admin_notes: this.props.row.admin_notes === `&nbsp;` ? `` : (this.props.row.admin_notes ?? ''),
                people: this.props.row.people,
                name: this.props.row.name,
                mobile: this.props.row.mobile,
                send_mobile: this.props.row.send_mobile,
                email: this.props.row.email,
                price: this.props.row.price,
                transfers: this.props.row.transfersValue,
                received_by: this.props.row.receivedValue,
                type: this.props.row.typeValue,
                confirmed: this.props.row.confirmedValue,
                who_put: this.props.row.who_put,
                google: this.props.row.googleValue,
                vip: this.props.row.vipValue,
                vaucher: this.props.row.vaucherValue,
                lang: this.props.row.lang,
            }
        }

        this.changeStateCheckBox = this.changeStateCheckBox.bind(this);
        this.changeState = this.changeState.bind(this);
        this.checkClubInfo = this.checkClubInfo.bind(this);
        this.sendMessageToClub = this.sendMessageToClub.bind(this);
        this.updateOrderInfo = this.updateOrderInfo.bind(this);
    }

    updateOrderInfo = async (noConfirm = false, send_order = false) => {
        const params = {
            data: Object.assign({}, this.state.data),
        };

        const check_params = {
            date: params.data.date,
            order_contact: params.data.club,
            name: params.data.name,
            mobile: params.data.mobile,
            email: params.data.email
        };
        // const check_params = (({ date, club, name, mobile, email }) => ({ date, club, name, mobile, email }))(params.data);
        const validated = this.validateFields(check_params);

        if (validated.size > 0) {
            this.setState({
                error_msg: 1,
                msg: 'The following fields are required: ' + [...validated].join(', ')
            });
            return false;
        }

        if (send_order) {
            params.data.confirmed = 'sent';
            this.setState({data: {...this.state.data, confirmed: 'sent'}});
        }

        if (noConfirm || window.confirm('Update Order ' + this.state.data.id + '?')) {
            await this.props.fetchUpdateOrder({type: primaryValues.$GET_UPDATE_ORDER, params});
            // await this.props.close_modal();
            return true;
        }

        return false;
    }
    deleteOrder = () => {
        let params = {order_id: this.state.data.id};
        if (window.confirm('Delete Order ' + this.state.data.id + '?')) {
            this.props.fetchDeleteOrder({type: primaryValues.$GET_DELETE_ORDER, params});
            // this.props.close_modal();
        }
    }
    checkColor = (val) => {
        switch (val) {
            case 'Yes':
                return 'green';
            case 'No':
                return 'red';
            default:
                return '#fcd767';
        }
    }
    changeState = (val, label, value) => {
        switch (label) {
            case 'Date':// this.state.data.date = val.target.value;
                this.setState({data: {...this.state.data, date: val}})
                break;
            case 'Time':
                this.setState({data: {...this.state.data, order_time: val}})
                break;
            case 'Order Contact': // this.state.data.club = val.target.value;
                this.setState({data: {...this.state.data, club: val.target?.value ? val.target.value : val}})
                break;
            case 'Notes': // this.state.data.notes = val.target.value;
                this.setState({data: {...this.state.data, notes: val.target.value}})
                break;
            case 'Admin Notes': // this.state.data.admin_notes = val.target.value;
                this.setState({data: {...this.state.data, admin_notes: val.target.value}})
                break;
            case 'People': // this.state.data.people = val.target.value;
                this.setState({data: {...this.state.data, people: val.target.value}})
                break;
            case 'Name'://  this.state.data.name = val.target.value;
                this.setState({data: {...this.state.data, name: val.target.value}})
                break;
            case 'Mobile': // this.state.data.mobile = val.target.value;
                this.setState({data: {...this.state.data, mobile: val.target.value}})
                break;
            case 'Send Mobile':
                this.setState({data: {...this.state.data, send_mobile: val.target.checked ? 'Yes' : 'No'}})
                break;
            case 'Email': // this.state.data.email = val.target.value;
                this.setState({data: {...this.state.data, email: val.target.value}})
                break;
            case 'Price': // this.state.data.price = val.target.value;
                this.setState({data: {...this.state.data, price: val.target.value}})
                break;
            case 'Transfers': //this.state.data.transfers = val.target.textContent;
                this.setState({data: {...this.state.data, transfers: val.target.textContent}})
                break;
            case 'Received By': //this.state.data.received_by = val.target.textContent;
                this.setState({data: {...this.state.data, received_by: val.target.textContent}})
                break;
            case 'Confirmed': //this.state.data.confirmed = val.target.textContent === 'Waiting' ? 'waiting' : val.target.textContent === 'Sent' ? 'padding' : val.target.textContent === 'Approved' ? 'approved' : 'cancel';
                this.setState({
                    data: {
                        ...this.state.data,
                        // confirmed: val.target.textContent === 'Waiting' ? 'waiting' : val.target.textContent === 'Sent' ? 'padding' : val.target.textContent === 'Aprovmed' ? 'aprovmed' : 'cancel'
                        confirmed: val.target.textContent.toLowerCase()
                    }
                })
                break;
            case 'Who Put': //this.state.data.who_put = val.target.textContent;
                this.setState({data: {...this.state.data, who_put: val.target.textContent}})
                break;
            case 'Google': //this.state.data.google = val.target.textContent;
                this.setState({data: {...this.state.data, google: val.target.textContent}})
                break;
            case 'Vip': //this.state.data.vip = val.target.textContent;
                this.setState({data: {...this.state.data, vip: val.target.textContent}})
                break;
            case 'Vaucher':// this.state.data.vaucher = val.target.textContent;
                this.setState({data: {...this.state.data, vaucher: val.target.textContent}})
                break;
            case 'Contact To Send': //this.state.club_selected = val.target.textContent;
                this.setState({club_selected: val.target.textContent})
                this.checkClubInfo(val.target.textContent);
                break;
            case 'Contact Phone': //this.state.inputClubSmsPhone = val.target.value;
                this.setState({data: {...this.state.data, inputClubSmsPhone: val.target.textContent}})
                break;
            case 'Language':
                this.setState({data: {...this.state.data, lang: value?.id ?? 'he'}})
                break;
            default: {

            }
        }
    }
    checkClubInfo = async (clubSelected) => {
        let clubs = this.state.clubs_list;

        this.setState({
            gmail: true,
            viber: true,
            sms: true,
            whatsapp: true,
            whatsappUrl: null,
            gmailUrl: null,
            viberUrl: null,
            address: ''
        });

        for (let i = 0; i < clubs.length; i++) {
            if (clubs[i].name && clubs[i].name.toLowerCase() === clubSelected.toLowerCase()) {
                if (clubs[i].whatsapp !== 'null') {
                    this.setState({whatsapp: false, whatsappUrl: clubs[i].whatsapp, address: clubs[i].address});
                }
                if (clubs[i].email !== 'null') {
                    this.setState({gmail: false, gmailUrl: clubs[i].email, address: clubs[i].address});
                }
                break;
            }
        }
    }

    /**
     * Validate if the fields are not empty.
     *
     * @param data
     * @param valuesFlag should be Set
     */
    validateFields = (data, valuesFlag = null) => {
        valuesFlag = valuesFlag || new Set();

        for (const val in data) {
            if (['price', 'order_time'].includes(val))
                continue;

            if (!`${data[val]}`.trim())
                valuesFlag.add(startCase(camelCase(val.replaceAll('_', ' '))));
        }

        return valuesFlag;
    }

    sendMessageToClub = async () => {
        this.setState({error_msg: 0, msg: ''});
        const valuesFlag = new Set();

        if (!this.state.whatsappChecked && !this.state.gmailChecked && !this.state.smsChecked) {
            this.setState({error_msg: 1, msg: 'Please select one or more send methods (Gmail, WhatsApp...)'});
            return;
        }

        try {
            const params = {
                user_email: this.state.data.email,
                order_id: this.state.data.id,
                club: this.state.club_selected,
                date: this.state.data.date,
                order_time: this.state.data.order_time,
                price: this.state.data.price,
                address: this.state.address,
                people: this.state.data.people,
                name: this.state.data.name,
            };

            if (this.state.whatsappChecked) {
                params.url = this.state.whatsappUrl;
                this.validateFields(params, valuesFlag);

                if (valuesFlag.size === 0 && window.confirm('Send Whatsapp Order ' + this.state.data.id + '?')) {
                    await this.updateOrderInfo(true, true);
                    params.whatsappMessage = this.sendWhatsappMessage(params);
                    await this.props.fetchWhatsappMessage({type: primaryValues.$GET_WHATSAPP_MESSEAGE, params});
                    // this.props.close_modal();
                } else {
                    this.setState({
                        error_msg: 1,
                        msg: 'The following fields are required: ' + [...valuesFlag].join(', ')
                    });
                    valuesFlag.clear();
                }
            }

            if (this.state.gmailChecked) {
                params.email = this.state.gmailUrl;
                this.validateFields(params, valuesFlag);

                if (valuesFlag.size === 0 && window.confirm('Send Gmail Order ' + this.state.data.id + '?')) {
                    await this.updateOrderInfo(true, true);
                    await this.props.fetchGmailMessage({type: primaryValues.$GET_GMAIL_MESSEAGE, params});
                    // this.props.close_modal();
                } else {
                    this.setState({
                        error_msg: 1,
                        msg: 'The following fields are required: ' + [...valuesFlag].join(', ')
                    });
                    valuesFlag.clear();
                }
            }

            if (this.state.smsChecked) {
                params.phone = this.state.inputClubSmsPhone;

                if (
                    params.phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
                    && params.phone.match(/\d/g).length >= 10
                    && params.phone.match(/\d/g).length <= 12
                ) {
                    this.validateFields(params, valuesFlag);

                    if (valuesFlag.size === 0 && window.confirm('Send Sms Order ' + this.state.data.id + ' to ' + params.phone + '?')) {
                        await this.updateOrderInfo(true, true);
                        await this.props.fetchSmsMessage({type: primaryValues.$GET_SMS_MESSAGE, params});
                        // this.props.close_modal();
                    } else {
                        this.setState({
                            error_msg: 1,
                            msg: 'The following fields are required: ' + [...valuesFlag].join(', ')
                        });
                        valuesFlag.clear();
                    }
                } else {
                    this.setState({error_msg: 1, msg: 'Check phone number..'});
                }
            }
        } catch (e) {
            this.setState({error_msg: 1, msg: e.message});
        }
    }

    sendWhatsappMessage(params, toClub = true) {
        const {url, order_id, club, name, date, order_time, people, address, user_email} = params;
        const url_params = {};
        let mobile_formatted = '',
            whatsapp_url = 'https://api.whatsapp.com/send/';

        // WhatsApp field is a link
        if (url.startsWith('http') && (url.includes('wa.me') || url.includes('whatsapp.com'))) {
            whatsapp_url = url;
        }

        // WhatsApp field is a number
        else {
            mobile_formatted = url.replaceAll('+', '').replaceAll(' ', '').replaceAll('-', '');

            if (mobile_formatted.startsWith('0')) {
                mobile_formatted = '972' + mobile_formatted.slice(1);
            }

            url_params.phone = mobile_formatted;
        }

        const approve_url = `${API_URL}sendMailView?people=${people}&date=${date}&name=${encodeURIComponent(name)}&order_id=${order_id}&club=${encodeURIComponent(club)}&address=${encodeURIComponent(address ?? '')}&user_email=${user_email}`;
        const with_mobile = this.state.data.send_mobile === 'Yes' ? `*Telephone:* ${this.state.data.mobile}\r\n` : '';
        const body = `*New Reservation!*\r\n\r\n*Order ID:* ${order_id}\r\n*Service:* ${club}\r\n*Full Name:* ${name}\r\n${with_mobile}*Date:* ${date}${order_time ? ' ' + order_time : ''}\r\n*People:* ${people}\r\n\r\n*Accept/Cancel Order:*\r\n${approve_url}`;

        if (toClub && !whatsapp_url.includes('text=')) {
            url_params.text = body;
        }

        if (Object.keys(url_params).length > 0) {
            whatsapp_url += (whatsapp_url.includes('?') ? '&' : '?') + new URLSearchParams(url_params).toString();
        }

        if (toClub) {
            copyStringToClipboard(body).then(() => {
                window.open(whatsapp_url, "_blank");
            });
        } else {
            window.open(whatsapp_url, "_blank");
        }

        return body;
    }

    sendWhatsappToCustomer = () => {
        const {mobile, id, email} = this.state.data;
        this.updateOrderInfo().then((r) => {
            if (r) {
                this.sendWhatsappMessage(Object.assign({
                    order_id: id,
                    user_email: email,
                    url: mobile
                }, this.state.data), false);
            }
        })
    }

    copyOrder = () => {
        this.props.copyOrderData({
            name: this.state.data.name,
            email: this.state.data.email,
            mobile: this.state.data.mobile,
            send_mobile: this.state.data.send_mobile,
            lang: this.state.data.lang,
        });
    }

    wordsReverser(str) {
        return str.replace(/[a-zA-Z]+/gm, function (item) {
            return item.split('').reverse().join('');
        });
    }

    sendVaucherToCustomer = () => {
        const params = {
            ...this.state.data,
            img_url: process.env.REACT_APP_HOST_URL + '/' + this.props.initialState.url_selected_image,
            order_id: this.state.data.id,
            address: this.state.address,
        };

        const check_params = {
            date: params.date,
            order_contact: params.club,
            name: params.name,
            mobile: params.mobile,
            email: params.email,
            address: params.address
        };

        const validated = this.validateFields(check_params);
        if (validated.size > 0) {
            this.setState({
                error_msg: 1,
                msg: 'The following fields are required: ' + [...validated].join(', ')
            });
            return;
        }
        this.props.fetchSendVaucherToCustomer({type: primaryValues.$GET_SEND_VAUCHER_CUSTOMER, params});
    }

    changeStateCheckBox = (params) => {
        this.setState({inputMobileOpacity: 0.3});

        if (params.Whatsapp) {
            this.setState({whatsappChecked: !this.state.whatsappChecked});
        }

        if (params.Gmail) {
            this.setState({gmailChecked: !this.state.gmailChecked});
        }

        if (params.Sms) {
            this.setState({
                smsChecked: !this.state.smsChecked,
                inputMobileOpacity: this.state.inputMobileOpacity === 1 ? 0.3 : 1
            })
        }
    }

    cancelOrder = () => {
        const params = {order_id: this.state.data.id};
        if (window.confirm('Cancel Order ' + this.state.data.id + '?')) {
            this.props.fetchCancelOrder({type: primaryValues.$GET_CANCEL_ORDER, params});
            this.setState({data: {...this.state.data, confirmed: 'cancel'}});
        }
    }

    render() {
        const {
            date,
            order_time,
            club,
            notes,
            admin_notes,
            people,
            name,
            send_mobile,
            mobile,
            email,
            price,
            id,
            lang
        } = this.state.data;
        const {address} = this.state;

        return (
            <div className='form' style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#ebebeb',
                minWidth: '100%'
            }}>
                <ReactTooltip/>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} className='form-row'>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 5}}>
                            <DatePick value={date} onChange={this.changeState}/>
                            <TimePick value={order_time} onChange={this.changeState}/>
                            {/* <Input icon={<IconsFa.FaRegCalendarAlt style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>}
                        inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={date + ''} label={'Date'} type={'text'} onChange={this.changeState} /> */}
                            <div className='input-app'>
                                <div className='input-app-inside'>
                                    <IconsFa.FaRegBuilding style={{
                                        color: '#2d2d2d',
                                        fontSize: 24,
                                        padding: 0,
                                        margin: 0,
                                        marginRight: 5
                                    }}/>
                                    <Autocomplete
                                        freeSolo
                                        style={{width: 250}}
                                        options={this.state.clubs_list.map((option) => option.name)}
                                        value={club + ''}
                                        onInputChange={(e, value) => this.changeState(value ?? '', 'Order Contact')}
                                        onChange={(e, value) => this.changeState(value ?? '', 'Order Contact')}
                                        filterOptions={(options, params) => options.filter(option => option.toLowerCase().includes(`${club}`.toLowerCase()))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputLabelProps={{style: {color: '#2d2d2d', fontWeight: 'bold'}}}
                                                label={"Order Contact"}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            {/*<AutocompleteApp freeSolo disableClearable icon={<IconsFa.FaRegBuilding*/}
                            {/*    style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}*/}
                            {/*                 labelColor={'#2d2d2d'} defaultValue={club + ''} value={club + ''} options={this.state.clubs_list}*/}
                            {/*                 label={'Order Contact'} onChange={this.changeState}/>*/}
                            <Input area align="right" dir="rtl" icon={<IconsMi.GoNote
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                   inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={notes + ''} label={'Notes'}
                                   type={'text'} onChange={this.changeState}/>
                            <Input icon={<IconsIo.IoIosPeople
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                   inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={people + ''} label={'People'}
                                   type={'text'} onChange={this.changeState}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 5}}>
                            <Input icon={<IconsMi.GoPerson
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                   inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={name + ''} label={'Name'}
                                   type={'text'} onChange={this.changeState}/>
                            <Input icon={<IconsMi.GoDeviceMobile
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                   inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={mobile + ''} label={'Mobile'}
                                   type={'text'} onChange={this.changeState}/>
                            <Input>
                                <FormControlLabel
                                    value="Yes"
                                    control={<Checkbox checked={send_mobile === 'Yes'}/>}
                                    label="Send Mobile"
                                    labelPlacement="end"
                                    onChange={e => {
                                        this.changeState(e, 'Send Mobile')
                                    }}
                                />
                            </Input>
                            <Input icon={<IconsMi.GoMail
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                   inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={email + ''} label={'Email'}
                                   type={'email'} onChange={this.changeState}/>
                            <label style={{
                                color: '#2d2d2d',
                                margin: '10px 10px -5px',
                                fontSize: '13px'
                            }}>Admin Notes</label>
                            <Input area align="right" dir="rtl" icon={<IconsMi.GoNote
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                   inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={admin_notes + ''} label={'Admin Notes'}
                                   type={'text'} onChange={this.changeState}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 5}}>
                            <AutocompleteApp icon={<IconsIo.IoIosSave
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={this.state.data.received_by + ''}
                                             options={[{name: 'System'}, {name: 'Office'}]} label={'Received By'}
                                             onChange={this.changeState}/>
                            <AutocompleteApp icon={<IconsFa.FaPencilAlt
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={this.state.data.who_put + ''}
                                             options={this.state.users} label={'Who Put'} onChange={this.changeState}/>
                            <AutocompleteApp icon={<IconsFa.FaBookOpen
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={this.state.data.confirmed + ''}
                                             options={[{name: 'Waiting'}, {name: 'In Process'}, {name: 'Sent'}, {name: 'Approved'}, {name: 'Cancel'}]}
                                             label={'Confirmed'} onChange={this.changeState}/>
                            <AutocompleteApp icon={<Icons.MdDirectionsTransit
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={this.state.data.transfers + ''}
                                             options={[{name: 'Yes'}, {name: 'No'}]} label={'Transfers'}
                                             onChange={this.changeState}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 5}}>
                            <AutocompleteApp icon={<IconsFa.FaGoogle
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={this.state.data.google + ''}
                                             options={[{name: 'Yes'}, {name: 'No'}]} label={'Google'}
                                             onChange={this.changeState}/>
                            <AutocompleteApp icon={<IconsIo.IoIosStar
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={this.state.data.vip + ''}
                                             options={[{name: 'Yes'}, {name: 'No'}]} label={'Vip'}
                                             onChange={this.changeState}/>
                            <AutocompleteApp icon={<Icons.MdSdCard
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={this.state.data.vaucher + ''}
                                             options={[{name: 'Yes'}, {name: 'No'}]} label={'Vaucher'}
                                             onChange={this.changeState}/>
                            <AutocompleteApp icon={<IconsFa.FaGlobe
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#2d2d2d'} defaultValue={lang ?? 'he'}
                                             disableClearable={true}
                                             options={[{id: 'he', name: 'Hebrew'}, {
                                                 id: 'en',
                                                 name: 'English'
                                             }, {id: 'el', name: 'Greek'}]} label={'Language'}
                                             onChange={this.changeState}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 5}}>
                            <AutocompleteApp icon={<Icons.MdReport
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                             labelColor={'#9d1d1d'} defaultValue={''} options={this.state.clubs_list}
                                             label={'Contact To Send'} onChange={this.changeState}/>
                            <div style={{
                                width: '100%',
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    color: '#9d1d1d',
                                    flex: 1,
                                    flexDirection: 'column',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{color: '#9d1d1d'}} data-tip="Whatsapp">
                                        <IconsFa.FaWhatsapp
                                            onClick={() => !this.state.whatsapp ? this.changeStateCheckBox({Whatsapp: !this.state.Whatsapp}) : null}
                                            style={{
                                                color: this.state.whatsapp ? '#2d2d2d' : 'green',
                                                fontSize: 24,
                                                padding: 0,
                                                margin: 0,
                                                marginRight: 5
                                            }}/>
                                        <input type="checkbox" disabled={this.state.whatsapp}
                                               checked={this.state.whatsappChecked}
                                               onChange={(e) => this.changeStateCheckBox({Whatsapp: e.target.value})}/>

                                    </div>
                                </div>
                                <div style={{
                                    color: '#9d1d1d',
                                    flex: 1,
                                    flexDirection: 'column',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{color: '#9d1d1d'}} data-tip="Viber">
                                        <IconsFa.FaViber
                                            onClick={() => !this.state.viber ? this.changeStateCheckBox({Viber: !this.state.Viber}) : null}
                                            style={{
                                                color: this.state.viber ? '#2d2d2d' : 'green',
                                                fontSize: 24,
                                                padding: 0,
                                                margin: 0,
                                                marginRight: 5
                                            }}/>
                                        <input type="checkbox" disabled={this.state.viber}
                                               checked={this.state.viberChecked}
                                               onChange={(e) => this.changeStateCheckBox({Viber: e.target.value})}/>

                                    </div>
                                </div>
                                <div style={{
                                    color: '#9d1d1d',
                                    flex: 1,
                                    flexDirection: 'column',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{color: '#9d1d1d'}} data-tip="Email">
                                        <IconsMi.GoMailRead
                                            onClick={() => !this.state.gmail ? this.changeStateCheckBox({Gmail: !this.state.Gmail}) : null}
                                            style={{
                                                color: this.state.gmail ? '#2d2d2d' : 'green',
                                                fontSize: 24,
                                                padding: 0,
                                                margin: 0,
                                                marginRight: 5
                                            }}/>
                                        <input type="checkbox" disabled={this.state.gmail}
                                               checked={this.state.gmailChecked}
                                               onChange={(e) => this.changeStateCheckBox({Gmail: e.target.value})}/>

                                    </div>
                                </div>
                                <div style={{
                                    color: '#9d1d1d',
                                    flex: 1,
                                    flexDirection: 'column',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{color: '#9d1d1d'}} data-tip="SMS">
                                        <IconsFa.FaSms
                                            onClick={() => !this.state.sms ? this.changeStateCheckBox({Sms: !this.state.Sms}) : null}
                                            style={{
                                                color: this.state.sms ? '#2d2d2d' : 'green',
                                                fontSize: 24,
                                                padding: 0,
                                                margin: 0,
                                                marginRight: 5
                                            }}/>
                                        <input type="checkbox" disabled={this.state.sms} checked={this.state.smsChecked}
                                               onChange={(e) => this.changeStateCheckBox({Sms: e.target.value})}/>

                                    </div>
                                </div>
                            </div>
                            <div style={{
                                opacity: this.state.inputMobileOpacity,
                                pointerEvents: this.state.inputMobileOpacity === 1 ? 'auto' : 'none'
                            }}>
                                <Input icon={<IconsFa.FaSms
                                    style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                       inputColor={'#2d2d2d'} labelColor={'#2d2d2d'}
                                       value={`+${this.state.inputClubSmsPhone}`} label={'Contact Phone'} type={'text'}
                                       onChange={this.changeState}/>
                            </div>
                            <Input align="right" dir="rtl" icon={<IconsIo.IoMdPricetag
                                style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                   inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={price + ''} label={'Price'}
                                   type={'text'} onChange={this.changeState}/>

                            <div style={{
                                color: '#9d1d1d',
                                flex: 1,
                                flexDirection: 'column',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div onClick={() => {
                                    window.open(`${API_URL}watchVaucher?order_id=${id}&name=${name}&club=${club}&price=${price}&address=${address}&people=${people}&date=${date}&order_time=${order_time}&lang=${lang}`, '_blank');
                                }} style={{color: '#9d1d1d', cursor: 'pointer'}}
                                     data-tip="watch voucher before send the order">
                                    <p>Watch Voucher</p>
                                </div>
                                <div onClick={() => {
                                    window.open(`${API_URL}downloadVaucher?order_id=${id}&name=${name}&club=${club}&price=${price}&address=${address}&people=${people}&date=${date}&order_time=${order_time}&lang=${lang}`, '_blank');
                                }} style={{color: '#9d1d1d', cursor: 'pointer'}}
                                     data-tip="download the voucher">
                                    <p>Download Voucher</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        minWidth: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 5
                    }}>
                        {this.state.error_msg ? <p style={{
                            fontSize: 14,
                            color: '#9d1d1d',
                            margin: 0,
                            marginBottom: 15,
                            fontWeight: 'bold'
                        }}>{this.state.msg}</p> : null}
                        <div style={{
                            minWidth: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            padding: 5
                        }}>
                            <Button onClick={this.sendMessageToClub} data-tip="Send order to club contact" name={'Send Order'} startIcon={
                                <IoIosSend/>}/>
                            <Button onClick={() => this.updateOrderInfo(false)} name={'Save Order'} startIcon={
                                <IoIosSave/>}/>
                            <Button color="secondary" data-tip="Send a cancellation email to customer" onClick={this.cancelOrder} name={'Cancel Order'} startIcon={
                                <IoIosClose/>}/>
                            <Button variant="outlined" color="secondary" onClick={this.deleteOrder} name={'Delete Order'} startIcon={
                                <IoIosTrash/>}/>
                            <Button variant="outlined" onClick={this.copyOrder} name={'Copy Order'} startIcon={
                                <IoIosCopy/>}/>
                            <Button color="default" onClick={this.sendWhatsappToCustomer} name={'Send WhatsApp To Customer'} endIcon={
                                <IoLogoWhatsapp/>}/>
                            <Button color="default" onClick={this.sendVaucherToCustomer} name={'Send Vaucher To Customer'} endIcon={
                                <IoIosSend/>}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};
const mapDispachToProps = dispatch => {
    return {
        fetchNewOrder: (info) => dispatch(info),
        fetchCancelOrder: (info) => dispatch(info),
        fetchSendVaucherToCustomer: (info) => dispatch(info),
    };
};
export default connect(mapStateToProps, mapDispachToProps)(TableInfo);