import React from 'react';
import '../style/InputApp.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutocompleteApp(props) {
    const toCapitalCase = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const getSelectedItem = () => {
        return props.options.find((opt) => {
            if (opt.id === props.defaultValue) {
                return true;
            } else if (toCapitalCase(opt.name).includes(props.defaultValue ? toCapitalCase(props.defaultValue + '') : null)) {
                return true;
            }
            return false;
        });
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return s + '';
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const defaultValue = props.defaultValue ? getSelectedItem() : undefined;

    return (
        <div className='input-app'>
            <div className='input-app-inside'>
                {props.icon}
                <Autocomplete
                    options={props.options}
                    getOptionLabel={option => capitalize(option.name)}
                    onChange={(e, value) => props.onChange(e, props.label, value)}
                    style={{width: 170}}
                    defaultValue={defaultValue}
                    renderInput={params => (
                        <TextField {...params}
                                   InputLabelProps={{style: {color: props.labelColor, fontWeight: 'bold'}}}
                                   label={props.label + ''} />
                    )}
                    inputValue={defaultValue?.name}
                    disableClearable={props.disableClearable ? props.disableClearable : false}
                />
            </div>
        </div>
    );
}
